import React, { useEffect, useRef } from "react";
import { injectIntl } from "gatsby-plugin-react-intl";
import SideVerticalText from "../components/layout/sideVerticalText";
import BottomFactory from "../components/layout/BottomFactory";
import FadeIn from "../components/animations/FadeIn";
import { gsap } from "gsap";
import ImageGallery from '../components/gallery/ImageGallery'



const Gallery = ({ intl }) => {
	let galleryHomeText = useRef(null);

	useEffect(() => {
		gsap.fromTo(
			galleryHomeText.current,
			{ opacity: 0 },
			{ duration: 3, opacity: 1, delay: 1 }
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	
	return (
		<div className="galleryPage">
			<div className="gallerySectionHome">
				<div className="boxGallerySide">
					<FadeIn duration="2s" delay="0.5s">
						<SideVerticalText />
					</FadeIn>
				</div>

				<div className="boxGalleryHome">
					<div className="galleryTitleDiv" ref={galleryHomeText}>
						<h1 className="galleryTitle">
							{intl.formatMessage({ id: "navbar.gallery" })}
						</h1>
						<p className="galleryText">
							{intl.formatMessage({ id: "gallery.homeText" })}
						</p>
					</div>
				</div>

				<div className="boxGalleryEmptyRight"></div>
				<div className="boxGalleryBottomText">
					<BottomFactory />
				</div>
			</div>
			<div className="gallerySectionGallery">
				<ImageGallery/>
				
			</div>
		</div>
	);
};

export default injectIntl(Gallery);
