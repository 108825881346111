import React from "react";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { graphql, useStaticQuery } from "gatsby";
import VideoButton from "./VideoButton";

const ImageGallery = () => {
	const data = useStaticQuery(graphql`
        {
            allImageGalleryJson {
                edges {
                    node {
                        title
						text
                        images {
                            childImageSharp {
                                original {
                                    src
                                }
                            }
                        }
                        videoId
                    }
                }
            }
        }
    `);


	return (
		<div className="galleryWrapper">
			{data.allImageGalleryJson.edges.map((item, index) => {
				const images = item.node.images.map((item) => {
					return item.childImageSharp.original;
				});
				return (
					<div className="machineGallery" key={index}>
						<div className="titleWrapper">
							<p className="galleryImageTilteText">
								<FormattedMessage id={item.node.title} />
							</p>
						</div>
						<div className="imagesWrapper">
							{typeof window !== "undefined" ? (
								<Carousel
									className="machineGalleryCarousel"
									images={images}
									autoPlayInterval={4000}
									hasIndexBoard={false}
								/>
							) : null}
						</div>
						<div className="videoButtonWrapper">
							{item.node.videoId.map((item, index) => {
								return <VideoButton videoId={item} key={index} />;
							})}
						</div>
						<div className="textWrapper">
							<p className="galleryImageTextText">
								<FormattedMessage id={item.node.text} />
							</p>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default ImageGallery;
