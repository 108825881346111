import React, { useState } from "react";
import { FormattedMessage } from "gatsby-plugin-react-intl";

import styled from "styled-components";
import ModalVideo from "react-modal-video";

/* import { TiSocialYoutube } from "react-icons/ti"; */

const Button = styled.button`
	/* padding: 0 !important;
	margin-right: 20px;
	border: none;
	outline: none;
	background: white;
	color: red; */
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	padding: 0 20px;
	width: 30%;
	border: none;
	outline: none;
	background: var(--mainGray);
	color: var(--mainWhite);
	font-family: 'Oxanium', cursive;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
	border-radius: 4px;
	transition: all .4s ease-in-out;
	&:hover {
      opacity: .7;
  }
	@media only screen and (max-width: 576px) {
	width: 90%;
	margin: 10px 0 15px 0;
	margin-right: 0;
	font-size: 11px;
	}
	@media only screen and (max-width: 260px) {
		font-size: 8px;
	}
`;

const VideoButton = (props) => {
	const [isOpen, setOpen] = useState(false);
	return (
		<>
			<ModalVideo
				channel='youtube'
				autoplay
				isOpen={isOpen}
				videoId={props.videoId}
				onClose={() => setOpen(false)}
			/>
			{/* <Button onClick={() => setOpen(true)}> <TiSocialYoutube className="youTubeVideoButton" size={50}/> </Button> */}
			<Button onClick={() => setOpen(true)}><FormattedMessage id="gallery.watch" /> </Button>
		</>
	);
};

export default VideoButton;
